module.exports = [{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149613592-1"},
    },{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sven Le Cann Blog","short_name":"SvenLC","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/slc-icon.png"},
    },{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/home/vsts/work/1/s/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
